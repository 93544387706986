define('webapp/locales/de/translations', ['exports', 'webapp/locales/de/translations-base'], function (exports, _translationsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends2;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, (_extends2 = {
    'account.ui.entryBannerImageNew': 'Lege ein Hauptbild für Dein Studio fest.',
    "application.ui.back": "zurück",
    'application.ui.after': 'nach',
    'application.ui.confirmDeleteEntry': 'Das Studio {{title}} unwiderruflich löschen?',
    'application.ui.downloadImage': 'Bild herunterladen',
    'application.ui.downloadCSV': 'CSV-Datei herunterladen',
    'application.ui.downloadZIP': 'Alle Dateien als ZIP herunterladen',
    'application.ui.entriesFocus': 'Ausgewählte Studios im Fokus:',
    'application.ui.entriesLatest': 'Neueste Studios im Fokus:',
    'application.ui.goToEntry': 'Studio aufrufen',
    'application.ui.newEntry': 'Neues Studio anlegen',
    'application.ui.noEntry': 'Sie haben noch keinen Studio angelegt. Jetzt gleich das erste Studio anlegen...',
    'application.ui.noEntryProfile': 'hat noch kein Studio angelegt.',
    'application.ui.showAllEntries': 'Alle Studios anzeigen',
    'application.widgets.newestEntries': 'Die neuesten Studios',
    'application.widgets.themeSwitch.active': 'Dark-Mode an. Ein Dankeschön von der Umwelt!',
    'application.widgets.themeSwitch.default': 'Energie sparen, Dark-Mode aktivieren.',
    'buttons.alreadyMeToo': 'Du magst dieses Studio.',
    'buttons.alreadyMeTooMobile': 'Du magst dieses Studio.',
    'entries.edit': 'Studio <strong>{{title}}</strong> bearbeiten',
    'entries.entries': 'Studios',
    'entries.entry.tooltip.alreadyCopied': 'Du magst diesen Studio bereits.',
    'entries.entry.tooltip.alreadyFollow': 'Du folgst diesem Studio bereits',
    'entries.entry.tooltip.alreadyLiked': 'Du magst diesen Studio bereits',
    'entries.entry.tooltip.copied': 'Schön, dass du dieses Studio magst. Dein Profilbild wurde diesem Studio hinzugefügt.',
    'entries.entry.tooltip.follow': 'Hier klicken, um diesem Studio zu folgen',
    'entries.entry.tooltip.like': 'Hier klicken, um diesem Studio ein Like zu geben',
    'entries.entry.tooltip.noFollow': 'Follow geht nur bei fremden Studios',
    'entries.entry.tooltip.noFollowNotLoggedIn': 'Du musst angemeldet sein, um diesem Studio zu folgen',
    'entries.entry.tooltip.noLike': 'Like geht nur bei fremden Studios',
    'entries.entry.ui.access': 'Sehen können dieses Studio nur',
    'entries.entry.ui.addEntry': 'Studios hinzufügen',
    'entries.entry.ui.allAccess': 'Alle können dieses Studio sehen',
    'entries.entry.ui.categories': 'Kategorien für dieses Studio',
    'entries.job.ui.documentCert': 'Zertifikat/Zeugnis',
    'entries.job.ui.documentCV': 'Lebenslauf',
    'entries.job.ui.documentCover': 'Anschreiben',
    'entries.job.ui.applications': 'Bewerbungen',
    'entries.job.ui.applications.job': 'Job',
    'entries.job.ui.applicant': 'Bewerber (m/w/d)',
    'entries.job.ui.applicant.notYetQualified': 'noch nicht qualifiziert',
    'entries.job.ui.applicant.notQualified': 'nicht qualifiziert',
    'entries.job.ui.applicant.qualified': 'qualifiziert',
    'entries.job.ui.assignedCategories': 'zugewiesene Kategorien',
    'entries.job.ui.assessment.interview': 'Interview geführt',
    'entries.job.ui.assessment.feedback': 'Feedback gegeben, gesendet',
    'entries.job.ui.assessment.worktest': 'Probearbeit abgenommen',
    'entries.job.ui.assessment.date': 'Termin für Entscheidung mitgeteilt',
    'entries.job.ui.assessment.talk': 'Gespräch zur Einstellung vereinbart',
    'entries.job.ui.assessment.offer': 'Jobangebot mitgeteilt, gesendet',
    'entries.job.ui.assessment.start': 'Startdatum vereinbart',
    'entries.entry.ui.tabCategories': 'eStore Aktionen',
    'entries.entry.ui.tabCategoriesMobile': 'eStore',
    'entries.entry.ui.promotion': 'Das Studio nimmt an folgenden Aktionen teil',
    'entries.entry.ui.regions': 'Regionenkategorien für dieses Studio',
    'entries.job.ui.promotion': 'Teilnahme an Aktionen für diesen Job',
    'entries.job.ui.region': 'Region',
    'entries.job.ui.start': 'Beginn',
    'entries.entry.ui.commercialTitle': 'Unternehmensinformationen zum Studio',
    'entries.entry.ui.editEntry': 'Studio bearbeiten',
    'entries.entry.ui.editEntryNew': 'Studio anlegen',
    'entries.entry.ui.isMainImageAndListImage': 'Das Hauptbild (Hero) des {{type}}',
    'entries.entry.ui.isMainImageInfo': 'Der gezeigte Ausschnitt entspricht ungefähr der Desktop-Darstellung, der gelbe Rahmen der mobilen Darstellung',
    'entries.entry.ui.noDescriptions': 'Das Studio hat noch keine Beschreibungstexte.',
    'entries.entry.ui.noEntries': 'Keine Studios gefunden.',
    'entries.application.ui.noEntries': 'Keine Bewerbungen vorhanden',
    'entries.entry.ui.onlyVisibleForCustomers': 'Dieses Studio ist nur für registrierte Kunden sichtbar',
    'entries.entry.ui.overallProgress': 'Dieses Studio ist zu {{value}}% beschrieben',
    'entries.entry.ui.skillsNeeded': 'Für mein Studio könnte ich gut gebrauchen...',
    'entries.entry.ui.title': 'Das Studio erscheint unter folgenden Namen',
    'entries.entry.ui.titleError': 'Der Studio muss vor dem Speichern benannt werden',
    'entries.entry.ui.visible': 'Dieser Studio ist sichtbar',
    'entries.open': 'Studio im Frontend aufrufen',
    'entries.person.ui.open': 'Direkt zum Studio dieser Person',
    'entries.entry.ui.toDetail': 'Direkt zu ',
    'entries.progress.title': 'So verbesserst du dein Studio',
    'entries.tabbar.entry': 'Studiodaten',
    'entries.tabbar.tooltip.entryNotSavedDetail': 'Studiodaten können erst bearbeitet werden, wenn der Studio mit Titel gespeichert wurde.',
    'entries.tabbar.tooltip.entryNotSavedLayout': 'Layoutdaten können erst bearbeitet werden, wenn der Studio mit Titel gespeichert wurde.',
    'entries.ui.groupDetailName': 'Name für Gruppeneinträge',
    'entryTypes.entry': 'Studio',
    'profile.entries.copycats': 'Studios, die {{userName}} auch machen will',
    'profile.entries.donates': 'Studios, die {{userName}} unterstützt',
    'profile.entries.entries': 'Studios von {{userName}}',
    'profile.entries.follows': 'Studios, denen {{userName}} folgt',
    'profile.entries.joins': 'Studios, bei denen {{userName}} mitmacht',
    'profile.marketplace.offer.relatedEntry': 'Zugeordneter Studio',
    'social.hasLiked': 'Du magst dieses Studio bereits.',
    'user.company.copyContact': 'Kontaktdaten aus Firmenangaben für den Studio übernehmen',
    'user.ui.addedStudios': 'sind angelegt.',
    'user.ui.accountMenuEntries': 'Meine Studios',
    'user.ui.accountMenuEntry': 'Studio',
    'user.ui.accountMenuEntryPre': 'Studios, ',
    'user.ui.accountMenuImages': 'Mein Verzeichnis',
    'user.ui.accountMenuFollows': 'Studios, denen ich folge',
    'user.ui.accountMenuLikes': 'Studios, die ich mag',
    'user.ui.accountMenuSupport': 'Unterstützte Studios',
    'user.ui.accountMenuSupports': 'Studios die ich Unterstütze',
    'user.ui.documents': 'Contentpool',
    'user.company.imprintFields': 'Impressumsangaben',
    'user.company.imprintCompany': 'Anbieter der Seite',
    'user.company.imprintCEO': 'Geschäftsführer/Inhaber',
    'user.company.imprintTaxId': 'Steuernummer',
    'user.company.imprintAddress': 'Anschrift',
    'user.company.imprintResponsible': 'Inhaltlich verantwortlich',
    'user.ui.ownDocuments': 'Dokumente für dieses Studio',
    'user.ui.ownVideos': 'Videos',
    'user.ui.user': 'Benutzer',
    'user.ui.user.complete': 'Ihre Benutzerdaten sind vollständig.',
    'user.ui.user.incomplete': 'Ihre Benutzerdaten könnten noch ergänzt werden. Wenn Sie Ihre Daten vervollständigen möchten, ergänzen Sie bitte folgende Felder:',
    'user.ui.user.verified': 'Ihr Benutzerkonto wurde überprüft und freigegeben.',
    'user.ui.salutation': 'Anrede',
    'user.ui.welcome': 'Willkommen, ',
    'user.ui.hasAdministrativeRights': 'Sie haben administrative Rechte und können weitere Benutzer Ihres Unternehmens einladen.',
    'entries.entry.ui.imagesInfo': "Bilder können unter \"Mein Verzeichnis\" bearbeitet werden.",
    'user.ui.accountProgressbarEntry': 'Dein Studio ist zu {{value}}% komplett',
    'user.company.addUserShort': 'Neuen Benutzer anlegen',
    'user.ui.company': 'Ihre Kundenangaben',
    'user.ui.noCompany': 'Für Ihren Benutzer-Account wurde noch kein Unternehmen angelegt. Kontaktieren Sie Ihren Ansprechpartner.',
    'user.ui.companyNameTooltip': 'Wichtig: Unter diesen Firmennamen erscheint dann auch Ihr Studio',
    'user.ui.dataDelete': ' weiteren Studios benutzt. Möchtest Du die Datei endgültig löschen? Überall, wo diese Datei verwendet wird, wird diese durch einen Platzhalter ersetzt.',
    'user.user': 'Benutzer',
    'user.users': 'Benutzer',
    'user.company.noEditingRights': 'Sie können noch keine Änderungen vornehmen, da Ihnen die notwendigen Rechte noch nicht zugewiesen sind.',
    'search.searchBarPlaceholderEntries': "Suchen Sie nach dem Studionamen oder der Studioadresse. ",
    'search.searchBarPlaceholderJobs': "Suche nach Jobtitel oder in deren Beschreibung. ",
    'search.searchBarPlaceholder.job': "Suche nach einem Jobtitel oder in dessen Beschreibung und drücke danach Enter...",
    'search.searchBarPlaceholderMobile.job': "Suche nach Job...",
    'search.searchBarPlaceholder.event': "Suche nach einem Veranstaltungstitel oder in dessen Beschreibung und drücke danach Enter...",
    'search.searchBarPlaceholder.entry': "Suche nach Studios oder in dessen Beschreibung und drücke danach Enter...",
    'search.searchBarPlaceholderEvents': "Suchen Sie nach Terminen oder Veranstaltungen. ",
    'search.searchBarPlaceholderDefault': "Suche... ",
    'search.searchBarPlaceholderGeoSearch': 'Dein Standort...',
    'search.searchBarPlaceholderArticles': 'Suche nach Artikeln in Titel und Text…',
    'search.searchBarPlaceholderArticlesMobile': 'Suche nach Artikel…',
    'search.searchTerm.pre': 'Nur Studios, gefiltert nach ',
    'search.backToResults': 'Zurück zur Ergebnisliste',
    'noResults': 'Keine Ergebnisse',
    'application.ui.flyout.receiver': 'Kontakt',
    'application.ui.flyout.bodystreet-trial': 'Probetraining',
    'application.ui.flyout.bodystreet-infopackage': 'Infopaket',
    'application.ui.flyout.cart': 'Shop',
    'application.ui.flyout.bodystreet-studio': 'Studios',
    'application.ui.flyout.bodystreet-shop': 'eStore',
    'application.ui.flyout.facebook': 'Facebook',
    'application.ui.flyout.sing': 'Xing',
    'application.ui.flyout.info': 'Info',
    'application.ui.flyout.bodystreet-info': 'Info',
    'application.ui.flyout.bodystreet-ems': 'Probetraining',
    'application.ui.flyout.bodystreet-voucher': 'Gutschein',
    'entries.entry.ui.externals': 'Schnittstellen',
    'entries.entry.ui.tabStudio': 'Studio',
    'entries.job.ui.relatedEntries': 'Studio, bei dem der Job erscheinen soll',
    'entries.entry.ui.bookTrial': 'Jetzt Probetraining buchen im Studio',
    'entries.entry.ui.bookVoucher': 'Jetzt Trainingsgutschein sichern für',
    'entries.entry.ui.voucherTitle': 'Euer persönlicher Trainingsgutschein für ',
    'entries.entry.ui.voucherIntro': 'Ihr wollt die wegweisende Bodystreet-Methode unverbindlich kennen lernen? Dann sichert euch gleich euren persönlichen Trainingsgutschein für ein komplettes Bodystreet Intensivtraining mit Personal Trainer und allem, was dazu gehört.',
    'entries.entry.ui.voucherInfo': 'So geht’s: Einfach oben Name, E-Mail-Adresse und Telefonnummer eintragen und absenden. Ihr erhaltet dann umgehend euren Gutschein per E-Mail und werdet vom Studio wegen der Terminabsprache angerufen. Vergesst bitte nicht, den Gutschein zum Training mitzubringen. Pro Person kann nur 1 Gutschein eingelöst werden.',
    'entries.entry.ui.goTrialSession': 'Zur Terminbuchung',
    'entries.entry.ui.noTrial': 'bietet zur Zeit keinen Gutschein an. Willst Du stattdessen direkt Kontakt aufnehmen?',
    'entries.entry.ui.trialSession': 'Probetraining',
    'entries.entry.ui.facebookPixel': 'Code für das Facebook Pixel auf Ihrer Studioseite',
    'entries.entry.ui.magicline': 'Meine Magicline ist für die Anzeige von Probetrainings eingerichtet.',
    'entries.entry.ui.magiclineNoID': 'Für Ihre Magicline ist keine ID hinterlegt. Sie können sich nicht verbinden. Wenden Sie sich an Ihren Ansprechpartner.',
    'entries.entry.ui.globalCampaign': 'Formular des allgemeinen Trainingsgutscheins anzeigen',
    'entries.entry.ui.provenExpertsID': 'ProvenExperts ID',
    'entries.entry.ui.getDirections': 'Dorthin navigieren',
    'entries.entry.ui.team': 'Unser Team',
    'entries.entry.ui.jobs': 'Stellenangebote',
    'entries.entry.ui.noRights': 'Dieses Feld kann nicht geändert werden.',
    'entries.entry.ui.articles': 'Interessante News',
    'entries.entry.ui.events': 'Veranstaltungen',
    'entries.entry.ui.jobApply': 'Jetzt bewerben',
    'entries.entry.ui.jobAvailableAt': 'Ist bei diesen Locations verfügbar',
    'entries.entry.ui.studio': 'Unser Studio',
    'entries.entry.ui.sharing': 'Diese Seite auf {{sharing}} teilen',
    'entries.entry.ui.shareMail': 'Diese Seite per Mail teilen',
    'entries.entry.ui.imageType.standard': 'Standard (max. 2 Bilder)',
    'entries.entry.ui.imageType.hero': 'Hero (Bannerbild)',
    'entries.entry.ui.imageType.product': 'Studiobild',
    'entries.entry.ui.imageType.cover': 'Coverbild',
    'entries.entry.ui.imageType.team': 'Personen- oder Teambild',
    'entries.entry.ui.imageType.award': 'Auszeichnung oder Siegel',
    'entries.entry.ui.imageType.action': 'Kampagnenbild',
    'entries.entry.ui.imageType.testimonial': 'Testimonial',
    'entries.entry.ui.tabRelations': 'Artikel & Kampagnen',
    'entries.entry.ui.tabRelationsMobile': 'Kampagnen',
    'entries.entry.ui.tabImagesMobile': 'Bild/Video',
    'entries.entry.ui.tabSkillsMobile': 'Skills',
    'entries.job.ui.tabSkills': 'Aufgaben/Leistungen',
    'entries.job.ui.yourDuties': 'Deine Aufgaben',
    'entries.job.ui.yourOpportunities': 'Deine Möglichkeiten',
    'entries.job.ui.yourProfile': 'Dein Profil',
    'entries.entry.ui.closed': 'geschlossen',
    'entries.entry.ui.miscellaneousSettings': 'Sonstige Einstellungen',
    'entries.entry.ui.provenExpertsSettings': 'ProvenExperts',
    'entries.entry.ui.globalPromotionSettings': 'Allgemeiner Trainingsgutschein',
    'entries.entry.ui.globalPromotionPrice': 'Text und Preisangabe für den Trainingsgutschein',
    'entries.entry.ui.facebookSettings': 'Facebook',
    'entries.entry.ui.facebookTracking': 'Facebook Tracking',
    'entries.entry.ui.calendly': 'Calendly URL Bestandteil',
    'entries.entry.ui.studioSoftware': 'Studio-Software',
    'entries.entry.ui.trialBooking': 'Suche dir einen Termin für ein Probetraining aus',
    'entries.entry.ui.socialPostOpen': 'Diesen Beitrag öffnen',
    'entries.entry.ui.openingWeek': 'Öffnungszeiten diese Woche',
    "entries.fields.zenplanner": "Kontaktieren Sie uns für ein Probetraining",
    'job.application.title': 'Deine Bewerbung',
    'job.application.thanks.salutation': 'Liebe/r Bewerber/in,',
    'job.application.thanks.title': 'vielen Dank für deine Bewerbung und das damit einhergehende Interesse, deinen nächsten Karriereschritt mit Bodystreet gehen zu wollen.',
    'job.application.thanks.content': 'Wir werden im nächsten Schritt deine Unterlagen bearbeiten und uns im Anschluss daran mit Dir in Verbindung setzen.',
    'job.application.thanks.contact': 'Du hast vorher schon ein paar wichtige Fragen? Dann kannst du uns gerne direkt kontaktieren.',
    'job.application.thanks.successWish': 'Wir wünschen dir viel Erfolg!',
    'job.application.studio': 'Studio',
    "job.application.noStudio": "Kein Studio zugewiesen, bitte zuweisen",
    'job.application.selectRegionCategory': 'Wähle eine Region aus, für die du dich bewerben willst',
    'job.application.personalData': 'Deine persönlichen Daten',
    'job.application.documents': 'Bewerbungsunterlagen',
    'job.application.selectStudio': 'Bitte mindestens ein Studio auswählen',
    'job.application.checkTransfer': 'Mit der Weitergabe meiner Bewerbung an andere Bodystreet Studios in der Umgebung bin ich einverstanden.',
    'job.application.privacyTitlePre': 'Ich bestätige, dass ich die ',
    'job.application.privacyTitle': 'für Bewerber zur Kenntnis genommen habe und willige ein, dass meine personenbezogenen Daten (Zutreffendes anklicken)',
    'job.application.privacyInfo': 'Eine darüberhinausgehende Datennutzung und Weitergabe meiner o.g. personenbezogenen Daten an Dritte erfolgt nicht. Diese datenschutzrechtliche Einwilligung kann ich jederzeit mit Wirkung für die Zukunft widerrufen.',
    'job.application.checkPrivacy': 'gespeichert und verwendet werden, um mich schriftlich, per E-Mail oder telefonisch im Rahmen des Bewerbungsprozesses zu kontaktieren und mich auch über weitere Stellenangebote der Bodystreet GmbH, der Bodystreet Trainings GmbH oder von Bodystreet Franchisepartnern zu informieren;',
    'job.application.checkApplicationPool': 'im Rahmen der Aufnahme in den Bodystreet Bewerberpool für die Dauer von maximal 6 Monaten gespeichert, in diesem Zeitraum an Bodystreet Franchisepartner übermittelt und von diesen gespeichert und verwendet werden, um mich schriftlich, per E-Mail oder telefonisch im Rahmen des Bewerbungsprozesses zu kontaktieren und mich auch über weitere Stellenangebote im Bodystreet System zu informieren.',
    'job.application.submit': 'Bewerbung jetzt absenden',
    'job.application.submitNotReady': 'Es müssen alle Pflichtfelder zum Absenden ausgefüllt sein',
    'job.application.coverNote': 'Anschreiben',
    'job.application.cv': 'Lebenslauf (Curriculum Vitae)',
    'job.application.photo': 'Bewerbungsbild',
    'job.application.certificate': 'Zeugnisse',
    'job.application.misc': 'Sonstige',
    'job.application.message': 'Anmerkung',
    'job.application.reason': 'Warum bewirbst Du Dich bei Bodystreet?',
    'job.application.trainer': 'Warum bist Du der perfekte Trainer?',
    'job.fields.yourDuties': 'Deine Aufgaben',
    'job.fields.yourOpportunities': 'Was du bei uns lernst',
    'job.fields.yourProfile': 'Dein Profil',
    'job.fields.jobPartner': 'Unsere Ausbildungs- und Studienpartner',
    'job.fields.jobContent': 'Deine Chancen + Vorteile',
    'job.fields.aboutUs': 'Das bieten wir dir',
    'job.fields.monthsOfExperience': 'Berufserfahrung',
    'job.fields.monthsOfExperienceContent': 'Für diese Stelle sind mindestens {{months}} Monate Berufserfahrung im Tätigkeitsbereich oder mit ähnlichen Tätigkeiten erforderlich.',
    'job.fields.monthsOfExperienceNone': 'Für diese Stelle ist keine Berufserfahrung nötig',
    'job.ui.noOffer': 'Zur Zeit keine offenen Stellen für diesen Job.',
    'job.ui.needed': 'Dringende Personalsuche',
    'job.ui.jobs': 'Jobs',
    'user.ui.phoneType': 'Telefon',
    'user.ui.phoneTypeMobile': 'Mobil',
    'user.ui.phoneTypeLandline': 'Telefon',
    'user.ui.fullName': 'Vor- und Nachname',
    'user.ui.dateWish': 'Wunschtermin',
    'user.ui.messageWish': 'Besondere Wünsche',
    'trial.calendar.today': 'Heute',
    'trial.calendar.form.privacy': 'Ja, die abgesendeten Daten werden zum Zweck der Bearbeitung meiner Anfrage verarbeitet und dürfen auch zur Kontaktaufnahme via Telefon oder E-Mail verwendet werden. Weitere Informationen finde ich in der Datenschutzerklärung.',
    'trial.calendar.form.yourTrailSession': 'Dein Probetraining am ',
    'trial.calendar.form.selectTrailSession': 'Wähle zuerst deinen Probetrainingstermin aus.',
    'trial.calendar.form.info': 'Bei Bodystreet wirst du während deines Probetrainings – und natürlich auch darüber hinaus – immer von einem Personal Trainer betreut.',
    'trial.calendar.form.submit': 'Jetzt deinen Termin sichern',
    'trial.calendar.form.trialSessionBooked': 'Deine Anfrage wurde entgegengenommen. Du solltest in Kürze eine Bestätigungsmail erhalten.',
    'trial.ptminder.title': 'Melde dich mit deinem Studio-Account an',
    'trial.ptminder.intro': 'Nachdem du einen Studio-Account erstellt hast oder dich mit deinem Studio-Account eingeloggt hast, kannst du deinen Probetrainings-Termin verbindlich buchen.',
    'menu.company.alternativeTitle': 'Werte',
    'campaign.title': 'Aktuelle Aktionen',
    'campaign.form.privacyInfo': 'Ich stimme zu, dass meine Angaben und Daten zur Bearbeitung und Beantwortung meiner Anfrage elektronisch verarbeitet und gespeichert werden. Die Einwilligung kann jederzeit für die Zukunft per E-Mail an das jeweilige Studio widerrufen werden.',
    'campaign.search.location': 'Deine Postleitzahl',
    'campaign.search.locationFull': 'Deine Postleitzahl oder Ort',
    'campaign.submit': 'Absenden',
    'campaign.selectLocation': 'Einfach Standort angeben, ',
    'campaign.selectStudio': 'Studio auswählen ',
    'campaign.selectedEntry': 'Ausgewähltes Probetraining-Studio:',
    'campaign.start': 'und los geht’s!',
    'campaign.form.intro': 'Ich möchte an der {{product}} im Studio <span class="form-studio-title">{{title}}</span> zum Preis von {{productPrice}} teilnehmen.',
    'campaign.form.introNoPrice': 'Gib hier deine Daten ein und erhalte gratis alle Informationen für {{product}} im Studio <span class="form-studio-title">{{title}}</span>.',
    "user.ui.uploadDrop": "Zum Hochladen ziehe eine Datei hierher oder ",
    "user.ui.uploadSelectFile": "wähle eine Datei aus",
    "user.ui.accountToolbarLoginTooltip": "Anmelden",
    "entries.entry.ui.jobReplyMail": "Bewerbungen an folgende E-Mail-Adresse schicken (wenn leer, geht die Mail an die Studio Kontakt-E-Mail-Adresse)",
    "entries.entry.ui.jobReplyMailInvalid": "Bitte eine gültige Bodystreet E-Mail-Adresse eingeben",
    "user.ui.newsletterEmail": "E-Mail",
    "user.ui.newsletterEmailnotValid": "Bitte deine E-Mail-Adresse eingeben.",
    "user.ui.newsletterEmailValid": "Jetzt abonnieren!",
    "map.ui.mapView": "Kartenansicht",
    "map.ui.listView": "Ergebnisliste",
    // store
    'checkout.delivery.deliveryDate': 'und voraussichtlich geliefert am ',
    'checkout.delivery.standard': 'Lieferung bis ',
    'checkout.delivery.express': 'Express-Lieferung bis ',
    'checkout.delivery.pickUp': 'Vor Ort abholen',
    'checkout.delivery.expressNotPossible': 'Sie haben eine Rechnungs- oder Lieferadresse angegeben, mit der kein Expressversand möglich ist.',
    'checkout.summary.withAccount': 'Sie bestellen mit den Kundenkonto-Einstellungen von ',
    'checkout.cart.checkoutWithAccount': 'Zur Kasse mit meinen Kontodaten',
    'checkout.cart.customerLocked': 'Ihr Kundenkonto wurde gesperrt. Bitte wenden sie sich an den Support.',
    'checkout.cart.additionalCosts': 'zusätzliche Kosten und Rabatte',
    'checkout.cart.delete': 'Warenkorb löschen',
    'checkout.cart.promocode': 'Promo-Code:',
    'checkout.cart.customerComment': 'Dein Kommentar zu deiner Bestellung',
    'checkout.cart.customerCommentInfo': 'Info: Wenn Du auf den Trainingsgutschein eine Nachricht haben möchtest, kannst Du diese hier eintragen.',
    'checkout.cart.addPosition': 'Angebotsposition hinzufügen',
    'checkout.cart.convertOffer': 'in Angebot umwandeln',
    'checkout.order.comment': 'Ihre Anmerkung zur Bestellung',
    'checkout.promotion.info': 'Haben Sie einen Promotioncode aus einer unserer Aktionen, den Sie für diese Bestellung einlösen wollen?',
    'checkout.promotion.notMinValue': 'Der Mindestbestellwert von € {{minValue}} für den aktuellen Promo-Code ist noch nicht erreicht.',
    'checkout.promotion.placeholder': 'Ihr Promotioncode',
    'checkout.promotion.invalid': 'Der eingegebene Promotionode ist nicht (mehr) gültig',
    'checkout.promotion.redeem': 'Einlösen',
    'account.order.rebuy': 'Erneut bestellen',
    'widgets.categoryFilter.reset': 'Filter zurücksetzen',
    'widgets.shoppingCart.wishlist': 'Merk- und Wunschliste',
    'widgets.shoppingCart.wishlist.empty': 'Sie haben noch keine Produkte auf Ihrer Merk- und Wunschliste',
    'widgets.shoppingCart.cart': 'Warenkorb',
    'entries.entry.ui.products.allCategories': 'Alle Produkte anzeigen',
    'entries.entry.ui.products.detail': 'Variante auswählen',
    'entries.entry.ui.products.notOrderable': 'kann derzeit nicht bestellt werden.',
    'entries.entry.ui.products.checkOutShow': 'Warenkorb anzeigen',
    'entries.entry.ui.products.checkOut': 'Weiter zur Kasse',
    'entries.entry.ui.products.chooseVariant': 'Bitte wählen Sie eine Variante aus',
    'entries.entry.ui.products.chooseVariantMobile': '← auswahl',
    'entries.entry.ui.products.notAvailable': 'nicht verfügbar',
    'entries.entry.ui.products.crosselling.title': 'Produkte, die Sie interessieren könnten',
    'entries.entry.ui.products.relatedArticles.title': 'Weiterführende Informationen zu diesem Produkt',
    'entries.entry.ui.products.delivery.red': 'Produkt zur Zeit nicht lieferbar',
    'entries.entry.ui.products.delivery.yellow': 'Produkt im Zulauf. Eventuell längere Lieferzeit.',
    'entries.entry.ui.products.delivery.green': 'Produkt in ausreichender Stückzahl auf Lager',
    'entries.products.ui.combinationNotAvailable': 'Produkt in dieser Kombination nicht verfügbar',
    'page.ui.privacy': 'Datenschutz',
    'page.ui.terms': 'Allgemeinen Geschäftsbedingungen (AGB)',
    'page.ui.revocation': 'Widerrufsbedingungen',
    'product.ui.pcs': 'Set',
    'product.ui.bundleItems': 'Im Set enthalten',
    'product.ui.bundleSet': 'Im folgenden Set enthalten',
    'product.ui.noIndividualPurchase': 'Kann nicht einzeln bestellt werden. Nur im Set erhältlich.',
    'product.ui.pickUp': 'Jetzt hier bestellen und in diesen Filialen abholen',
    'product.ui.cartEmpty': 'Du hast noch keine Produkte im Einkaufskorb.',
    'product.ui.productOverview': 'Alle Produkte in der Übersicht zum Stöbern.',
    'user.ui.accountMenuCompanySettings': 'Firmenangaben',
    'account.ui.company': 'Rechnungs- und Lieferadressen',
    'account.company.address.isDefault': 'Diese Adresse ist auch meine Standard-Lieferadresse',
    'account.ui.companyAddOn': 'Weiterer Kontakt',
    'account.ui.companyContact': 'Abweichende Lieferadresse',
    'account.ui.companyWant': 'Ich möchte Geschäftskunde werden',
    'account.ui.deliveryAddress': 'Lieferadresse',
    'account.ui.invoiceAddress': 'Rechnungsanschrift',
    'account.company.address.setDefault': 'Als Standardadresse festlegen',
    'account.company.address.isDeliveryDefault': 'Standard-Lieferadresse',
    'account.order.where': 'im',
    'country.DE': 'Inland',
    'country.EU': 'EU',
    'country.NOEU': 'Welt',
    'checkout.checkout.status': 'Zahlung und Versand',
    'checkout.checkout.delivery.addressNotComplete': 'Deine E-Mail-Adresse fehlt oder deine Rechnungs- und Lieferadresse ist noch nicht komplett. Bitte vervollständige deine Eingabe.'
  }, _defineProperty(_extends2, 'user.ui.accountMenuCompanySettings', 'Unternehmensangaben'), _defineProperty(_extends2, 'user.ui.accountMenuWishlist', 'In meiner Merkliste'), _defineProperty(_extends2, 'user.ui.removeFromWishlist', 'Aus der Merkliste entfernen'), _defineProperty(_extends2, 'user.ui.accountOrdersEmpty', 'Sie haben noch nichts bestellt.'), _defineProperty(_extends2, 'user.ui.accountMenuWishlistEmpty', 'Sie haben noch keine Produkte zu Ihrem Merkzettel hinzugefügt.'), _defineProperty(_extends2, 'widget.gdpr.personalisation', 'Personalisierte Inhalte, Performancemessung, Entwicklung und Verbesserung von personalisierten Angeboten'), _defineProperty(_extends2, 'widget.gdpr.advertising', 'Audience Messungen mit Speicherung und Zugriff auf Informationen auf einem Endgerät'), _defineProperty(_extends2, 'widget.gdpr.social', 'Social Media Cookies'), _defineProperty(_extends2, 'dialog.promptAcceptJobApplication', 'Klicke OK, um die Bewerbung anzunehmen. Das Jobangebot wird deaktiviert. Du musst den Bewerber noch von deiner Entscheidung in Kenntnis setzen.'), _defineProperty(_extends2, 'dialog.promptRejectJobApplication', 'Klicke OK, um die Bewerbung abzulehnen. Der Bewerber wird automatisch über deine Entscheidung informiert.'), _defineProperty(_extends2, 'dialog.saved.status', 'Job, Bewerbungsänderungen gespeichert'), _extends2));
});